<template>
  <div id="app">
    <div style="flex-direction: row; display: flex; width: 90%">
      <h1>Correction</h1>
    </div>
    <div class="keypoint-box">
      <div id="spinner"><img :src="require('@/assets/images/spinner.gif')" alt="Next image is loading"/></div>
      <div id="waitloading"></div>
      <canvas ref="canvas"
            id="myCanvas"
            style="border: solid 2px black; cursor: crosshair;"
            width="800" height="500"
            @mousemove="draw"
            @mousedown.left="toolSelection"
            @mouseup="stopDrawing"
      />
     <div style="margin-left: 20px; height: 600px">
       <h6>Annotations to be corrected: {{this.imageAmount}}</h6>
      <h3>Correction Toolbox</h3>
      <div style="overflow-y:auto; height:400px; margin-top: 10px;">
        <table class="table table-bordered">
          <thead>
          <tr>
            <th scope="col">Segmentation: {{this.segmentationID[0]}}</th>
            <th scope="col">Status</th>
            <th scope="col" class="text-center">#</th>
            <th scope="col" class="text-center">#</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(k,index) in keypoints" :key="index">
            <td style="display: flex">{{k.name}}
              <div :style="{'background-color': k.color}" style="width: 10px; height: 10px; align-self: center; margin-left: auto; margin-right: 0;">
              </div></td>
            <td>{{k.status}}</td>
            <td>
              <div class="text-center">
                <span class="fa fa-check" @click="saveKeyPoints(index)" style="cursor: pointer"></span>
              </div>
            </td>
            <td>
              <div class="text-center">
                <span class="fa fa-trash" @click="partialDelete(index)" style="cursor: pointer"></span>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
       <div>
        <button ref="penbtn" @click="drawingTool" type="button" style="width: 70px; text-align: center; align-self: center; margin-left: 15px; margin-top: 10px" class="hollow button secondary">Pen</button>
        <button ref="eraserbtn" @click="eraserTool" class="hollow button secondary" style="width: 70px; text-align: center; align-self: center; margin-left: 15px; margin-top: 10px;" type="button">Eraser</button>
        <button ref="keypointbtn" @click="keypointsTool" class="hollow button secondary" style="width: 95px; text-align: center; align-self: center; margin-left: 15px; margin-top: 10px" type="button">Keypoints</button>
      </div>
       <button ref="nextButton" class="hollow button secondary" style="text-align: center; align-self: center; margin-left: 122px; margin-top: 10px" @click="next">Next</button>
    </div>
  </div>
  </div>
</template>

<script>
import axios from "axios";
import {VUE_APP_BASE_URL} from "../../../config/dev.env";
import $ from 'jquery';

export default {
  name:"Correction",
  data(){
    return{
      segmentationFit: "No",
      fileName: null,
      startX: null,
      startY: null,
      endX: null,
      endY: null,
      canvas: null,
      sendArray: [],
      imageNameArray: [],
      annotation: [],
      drawColor: "yellow",
      imageAmount: null,
      imgWidth: "800",
      imgHeight: "500",
      loadingImage: null,
      sendingArray: Array.from(Array(34).fill(0)),
      emptyPoint: false,
      isSaved: "saved",
      notSaved: "-",
      zeroCounter:0,
      xRatio: null,
      yRatio: null,
      lock: true,
      checkColorIndex: true,
      segmentationID: [],
      checkIfSaved: true,
      images: null,
      tool: null,
      draw_width: 5,
      checkFirst:false,
      isDrawing: false,
      defaultImage: [],
      dataPoints: [],
      colorIndex:0,
      eraserArray: [],
      eraserWidth: 8,
      pointCounter: 0,
      isPress: false,
      xYRatio: [],
      undoArray:[],
      index: -1,
      hexColors:
          ['#F01410'],
      points:[],
      bbox:[
          {
              mostLeftX: null,
              mostRightX: null,
              mostUpY: null,
              mostDownY: null
            }
      ],
      keypoints: [
          {
          point: 0,
          name:"Nose",
          status: "-",
          color:'',
          data_points: []
        },
          {
            point: 0,
            name:"Left Eye",
            status: "-",
            color:'',
            data_points:[],
          },
        {
          point: 0,
          name:"Right Eye",
          status: "-",
          color:'',
          data_points: []
        },
        {
          point: 0,
          name:"Left Ear",
          status: "-",
          color:'',
          data_points: []
        },
        {
          point: 0,
          name:"Right Ear",
          status: "-",
          color:'',
          data_points: []
        },
        {
          point: 0,
          name:"Left Shoulder",
          status: "-",
          color:'',
          data_points: []
        },
        {
          point: 0,
          name:"Right Shoulder",
          status: "-",
          color:'',
          data_points: []
        },
        {
          point: 0,
          name:"Left Elbow",
          status: "-",
          color:'',
          data_points: []
        },
        {
          point: 0,
          name:"Right Elbow",
          status: "-",
          color:'',
          data_points: []
        },
        {
          point: 0,
          name:"Left Wrist",
          status: "-",
          color:'',
          data_points: []
        },
        {
          point: 0,
          name:"Right Wrist",
          status: "-",
          color:'',
          data_points: []
        },
        {
          point: 0,
          name:"Left Hip",
          status: "-",
          color:'',
          data_points: []
        },
        {
          point: 0,
          name:"Right Hip",
          status: "-",
          color:'',
          data_points: []
        },
        {
          point: 0,
          name:"Left Knee",
          status: "-",
          color:'',
          data_points: []
        },
        {
          point: 0,
          name:"Right Knee",
          status: "-",
          color:'',
          data_points: []
        },
        {
          point: 0,
          name:"Left Ankle",
          status: "-",
          color:'',
          data_points: []
        },
        {
          point: 0,
          name:"Right Ankle",
          status: "-",
          color:'',
          data_points: []
        }
      ]
    }
  },
  methods: {
    //-------------------------------- DRAWING FUNCTIONS
    eraserTool(){
      if (this.checkIfSaved){
        this.tool = "Eraser";
        this.$refs.eraserbtn.style.background="#d8d8d8";
        this.$refs.keypointbtn.style.background="#FFFFFF";
        this.$refs.penbtn.style.background="#FFFFFF";
      }
    },
    keypointsTool(e){
      this.$refs.keypointbtn.style.background="#d8d8d8";
      this.$refs.penbtn.style.background="#FFFFFF";
      this.$refs.eraserbtn.style.background="#FFFFFF";
      this.tool = "Keypoints"
      this.setKeypoint(e);
      this.checkIfSaved = true;
    },
    drawingTool(){
      if (this.checkIfSaved){
        this.$refs.penbtn.style.background="#d8d8d8";
        this.$refs.keypointbtn.style.background="#FFFFFF";
        this.$refs.eraserbtn.style.background="#FFFFFF";
        this.tool = "Pen"
        this.isPress = false;
        this.isDrawing = false;
      }
    },
    toolSelection(e){
      if (this.tool === "Eraser" && this.checkIfSaved === true){
        this.beginDrawing(e);
        this.isPress = true;
        this.draw(e);
      }
      else if (this.tool === "Keypoints"){
        this.setKeypoint(e);
      }
      else if (this.tool === "Pen" && this.checkIfSaved === true){
        this.beginDrawing(e);
        this.draw(e);
        }
      },
    draw(e) {
      if(this.isDrawing) {
        //This is the eraser part
        if(!this.isPress){
          this.drawLines(this.x, this.y, e.offsetX, e.offsetY);
          this.eraserArray.push({
            prevX: this.x,
            prevY: this.y,
            x: e.offsetX,
            y: e.offsetY
          })
          this.points.push({
            x: e.offsetX * this.xRatio,
            y: e.offsetY * this.yRatio
          })
          }
        else {
          Array.prototype.insert = function ( index, item ) {
            this.splice( index, 0, item );
          };
          for (let i = 0; i< this.eraserArray.length; i++){
            if (Math.abs(this.eraserArray[i].prevX - e.offsetX) <= this.eraserWidth && Math.abs(this.eraserArray[i].prevY - e.offsetY) <= this.eraserWidth){
              this.eraserArray.insert(i,{prevX: -1, prevY: -1, x: -1, y: -1})
              this.eraserArray.splice(i+1,1)
              this.clearCanvas();
              this.drawSegmentation();
              this.drawConnectionLines();
            }
          }
          this.dataPoints = [];
          for (let i = 0; i<this.eraserArray.length; i++){
              this.dataPoints.push(this.eraserArray[i].prevX, this.eraserArray[i].prevY);
            }
          }
        }
        this.x = e.offsetX;
        this.y = e.offsetY;
    },
    drawSegmentation(){
      for (let i = 0; i< this.eraserArray.length; i++) {
        if (this.eraserArray[i].prevX >= 0) {
        this.drawLines(this.eraserArray[i].prevX, this.eraserArray[i].prevY,this.eraserArray[i].x, this.eraserArray[i].y);
        }
      }
      for (let i = 0; i < this.sendingArray.length/2; i++){
        if (this.sendingArray[i*2]!== 0){
          this.canvas.beginPath();
          this.canvas.arc(this.sendingArray[i*2]/this.xRatio, this.sendingArray[i*2+1]/this.yRatio, 5, 0, 2 * Math.PI)
          this.canvas.fillStyle = this.hexColors[0];
          this.canvas.fill();
        }
      }
    },
    drawLines(x1, y1, x2, y2) {
      let ctx = this.canvas;
      ctx.beginPath();
      ctx.strokeStyle = this.drawColor;
      ctx.lineWidth = this.draw_width;
      ctx.lineCap="round";
      ctx.moveTo(x1, y1);
      ctx.lineTo(x2, y2);
      ctx.stroke();
      ctx.closePath();
    },
    beginDrawing(e) {
      this.isDrawing = true;
      if(!this.isPress){
        this.x = e.offsetX;
        this.y = e.offsetY;
        this.points.push({
            x: e.offsetX * this.xRatio,
            y: e.offsetY * this.yRatio
          })
        this.dataPoints.push(this.x * this.xRatio,this.y * this.yRatio);
      }
      },
    stopDrawing(e) {
      if (this.isDrawing && !this.isPress) {
        this.drawLine(this.x, this.y, e.offsetX, e.offsetY);
        this.x = 0;
        this.y = 0;
        this.isDrawing = false;
        this.index+=1;
        this.undoArray.push(this.canvas.getImageData(0, 0, this.imgWidth, this.imgHeight));
        // shows the segmentation where it stopped
        //console.log(this.eraserArray)
      }
      else {
        this.isDrawing = false;
        this.undoArray.push(this.canvas.getImageData(0, 0, this.imgWidth, this.imgHeight))
      }
    },
    setKeypoint(e){
      if (this.pointCounter === 0) {
        const rect = this.$refs.canvas.getBoundingClientRect();
        this.startX = e.clientX - rect.left -2.5; // -2.5 is to center the point in the cursor
        this.startY = e.clientY - rect.top -2;
        this.canvas.beginPath();
        this.canvas.arc(this.startX, this.startY, 5, 0, 2 * Math.PI)
        this.canvas.fillStyle = this.hexColors[0];
        this.canvas.fill();
        this.points.push(this.startX, this.startY);
        this.undoArray.push(this.canvas.getImageData(0, 0, this.imgWidth, this.imgHeight));
        this.index += 1;
        this.pointCounter += 1;
        this.emptyPoint = true;
        this.checkIfSaved = false;
      }
      else {
        this.undo();
        this.pointCounter = 0;
        this.points = [];
        this.setKeypoint(e);
        this.drawExistingKeypoints();
        this.drawConnectionLines();
        this.drawSegmentation();
        this.checkIfSaved = false;
      }
    },
    partialDelete(index){
      if (this.checkIfSaved){
        this.canvas.putImageData(this.defaultImage.at(-1),0,0);
        this.keypoints[index].point=0;
        for (let i = 0; i < this.keypoints.length; i++){
          if (this.keypoints[i].point===1){
          this.canvas.beginPath();
          this.canvas.arc(this.keypoints[i].data_points[0], this.keypoints[i].data_points[1], 5, 0, 2 * Math.PI)
          this.canvas.fillStyle = this.keypoints[i].color;
          this.canvas.fill();
          }
        }
        this.deleteKeyPoints(index);
        this.sendingArray[index*2] = 0;
        this.sendingArray[index*2+1] = 0;
        this.undoArray.push(this.canvas.getImageData(0, 0, this.imgWidth, this.imgHeight));
        this.index += 1;
        this.drawSegmentation();
        this.drawConnectionLines();
      }
    },
    clearCanvas(){
      this.canvas.drawImage(this.img,0,0,this.imgWidth,this.imgHeight)
      this.index= -1;
      this.undoArray=[];
    },
    clear_canvas(){
      if (this.undoArray.length!==0){
        this.canvas.putImageData(this.defaultImage.at(-1),0,0);
        this.index= -1;
        this.undoArray=[];
      }
    },
    undo(){
      if (this.index>0){
        this.index-= 1;
        this.canvas.putImageData(this.defaultImage.at(-1),0,0);
      }
      else {
        this.clearCanvas();
        this.index= -1;
        this.undoArray=[];
      }
    },
    drawConnectionLines(){
      // Left eye - nose
      if(this.keypoints[0].point === 1 && this.keypoints[1].point === 1){
        this.drawLine(this.keypoints[0].data_points, this.keypoints[1].data_points)
      }
      // right eye - nose
      if(this.keypoints[0].point === 1 && this.keypoints[2].point === 1){
        this.drawLine(this.keypoints[0].data_points, this.keypoints[2].data_points)
      }
      // Left ear - left eye
      if(this.keypoints[3].point === 1 && this.keypoints[1].point === 1){
        this.drawLine(this.keypoints[3].data_points, this.keypoints[1].data_points)
      }
      // right ear - right eye
      if(this.keypoints[4].point === 1 && this.keypoints[2].point === 1){
        this.drawLine(this.keypoints[4].data_points, this.keypoints[2].data_points)
      }
      // left shoulder - right shoulder
      if(this.keypoints[5].point === 1 && this.keypoints[6].point === 1){
        this.drawLine(this.keypoints[5].data_points, this.keypoints[6].data_points)
      }
      // left shoulder - left elbow
      if(this.keypoints[5].point === 1 && this.keypoints[7].point === 1){
        this.drawLine(this.keypoints[5].data_points, this.keypoints[7].data_points)
      }
      // right shoulder - right elbow
      if(this.keypoints[6].point === 1 && this.keypoints[8].point === 1){
        this.drawLine(this.keypoints[6].data_points, this.keypoints[8].data_points)
      }
      // left elbow - left wrist
      if(this.keypoints[7].point === 1 && this.keypoints[9].point === 1){
        this.drawLine(this.keypoints[7].data_points, this.keypoints[9].data_points)
      }
      // right elbow - right wrist
      if(this.keypoints[8].point === 1 && this.keypoints[10].point === 1){
        this.drawLine(this.keypoints[8].data_points, this.keypoints[10].data_points)
      }
      // left shoulder - left hip
      if(this.keypoints[5].point === 1 && this.keypoints[11].point === 1){
        this.drawLine(this.keypoints[5].data_points, this.keypoints[11].data_points)
      }
      // right shoulder - right hip
      if(this.keypoints[6].point === 1 && this.keypoints[12].point === 1){
        this.drawLine(this.keypoints[6].data_points, this.keypoints[12].data_points)
      }
      // left hip - right hip
      if(this.keypoints[11].point === 1 && this.keypoints[12].point === 1){
        this.drawLine(this.keypoints[11].data_points, this.keypoints[12].data_points)
      }
      // left hip - left knee
      if(this.keypoints[11].point === 1 && this.keypoints[13].point === 1){
        this.drawLine(this.keypoints[11].data_points, this.keypoints[13].data_points)
      }
      // right hip - right knee
      if(this.keypoints[12].point === 1 && this.keypoints[14].point === 1){
        this.drawLine(this.keypoints[12].data_points, this.keypoints[14].data_points)
      }
      // left knee - left ankle
      if(this.keypoints[13].point === 1 && this.keypoints[15].point === 1){
        this.drawLine(this.keypoints[13].data_points, this.keypoints[15].data_points)
      }
      // right knee - right ankle
      if(this.keypoints[14].point === 1 && this.keypoints[16].point === 1){
        this.drawLine(this.keypoints[14].data_points, this.keypoints[16].data_points)
      }
    },
    drawLine(start,end){
      this.canvas.beginPath();
      this.canvas.lineWidth = 2;
      this.canvas.strokeStyle = "red";
      this.canvas.moveTo(start[0],start[1]);
      this.canvas.lineTo(end[0],end[1]);
      this.canvas.stroke();
    },
    saveKeyPoints(index){
      if (this.emptyPoint === true && this.keypoints[index].status === "-") {
        this.keypoints[index].point = 1;
        this.keypoints[index].data_points = this.points;
        this.sendingArray[index*2] = this.points[0]*this.xRatio;
        this.sendingArray[index*2+1] = this.points[1]*this.yRatio;
        this.keypoints[index].status = this.isSaved;
        this.keypoints[index].color = this.hexColors[0];
        this.points = [];
        this.pointCounter = 0;
        this.emptyPoint = false;
        this.checkIfSaved = true;
        this.undoArray.pop();
        this.undoArray.push(this.canvas.getImageData(0, 0, this.imgWidth, this.imgHeight));
        this.drawConnectionLines();
      }
    },
    deleteKeyPoints(index){
      this.keypoints[index].point = 0;
      this.keypoints[index].data_points = [];
      this.keypoints[index].status=this.notSaved;
      this.keypoints[index].color = '';
      this.annotation[0].keypoints[index*2] = 0;
      this.annotation[0].keypoints[index*2+1] = 0;
      this.sendingArray[index*2] = 0;
      this.sendingArray[index*2+1] = 0;
    },

    searchForKeyPoints(){
      if (this.annotation.length!==0 && this.eraserArray.length!==0){
        var xMax = this.eraserArray[0].x;
        var yMax = this.eraserArray[0].y;
        var xMin = this.eraserArray[0].prevX;
        var yMin = this.eraserArray[0].prevY;
        this.dataPoints = [];
        let counter = 0;
        for (let i = 0; i < this.eraserArray.length; i++) {
          if (this.eraserArray[i].prevX < 0 && counter < 1){
            counter += 1;
            this.dataPoints.push(-1, -1)
          }
          else if (this.eraserArray[i].prevX >= 0){
            this.dataPoints.push(this.eraserArray[i].x * this.xRatio, this.eraserArray[i].y*this.yRatio)
            counter = 0;
          }if (this.eraserArray[i].x > xMax){
              xMax = this.eraserArray[i].x;
          }if (this.eraserArray[i].y > yMax){
              yMax = this.eraserArray[i].y;
          }if (this.eraserArray[i].x < xMin && this.eraserArray[i].x > 0){
              xMin = this.eraserArray[i].x;
          }if (this.eraserArray[i].y < yMin && this.eraserArray[i].y > 0){
              yMin = this.eraserArray[i].y
          }
        }
        this.dataPoints.push(this.eraserArray[this.eraserArray.length-1].x*this.xRatio, this.eraserArray[this.eraserArray.length-1].y*this.yRatio)
        this.bbox[0].mostRightX = xMax * this.xRatio;
        this.bbox[0].mostDownY = yMax * this.yRatio;
        this.bbox[0].mostLeftX = xMin * this.xRatio;
        this.bbox[0].mostUpY = yMin * this.yRatio;
      }
    },

    async saveKeypointsToDB(){
      for (let i = 0; i <this.dataPoints.length; i++){
        if (this.dataPoints[i] !== -1){
             this.sendArray.push(this.dataPoints[i])
        }
      }
      await axios.put(VUE_APP_BASE_URL+"/correction/"+this.segmentationID[0],
          {
            segmentation: this.sendArray,
            keypoints: this.sendingArray,
            bbox: this.bbox,
            meta_data: {
              "filename": this.imageNameArray[0],
              "corrected": "true",
            },
          })
      this.sendArray = []
    },
    // need to check what is going wrong here but partially works
    drawExistingKeypoints(){
      if (this.annotation.length !== 0){
        for (let i = 0; i<this.annotation[0].keypoints.length/2; i++){
        if (this.annotation[0].keypoints[i*2] !== 0 || this.annotation[0].keypoints[i*2+1] !== 0){
          this.keypoints[i].data_points = [this.annotation[0].keypoints[i*2]/this.xYRatio[0],
                                          this.annotation[0].keypoints[i*2+1]/this.xYRatio[1]];
          this.keypoints[i].point = 1;
          this.keypoints[i].status = this.isSaved;
          this.keypoints[i].color = this.hexColors[0];
          this.startX = this.keypoints[i].data_points[0]; // -2.5 is to center the point in the cursor
          this.startY = this.keypoints[i].data_points[1];
          this.canvas.beginPath();
          this.canvas.arc(this.startX, this.startY, 5, 0, 2 * Math.PI)
          this.canvas.fillStyle = this.hexColors[0];
          this.canvas.fill();
          this.sendingArray[i*2] = this.annotation[0].keypoints[i*2];
          this.sendingArray[i*2+1] = this.annotation[0].keypoints[i*2+1];
        }
      }
      if(this.checkColorIndex){
        this.colorIndex+=1;
        this.checkColorIndex = false;
      }
      this.drawConnectionLines();
      }

    },
    drawSegmentationPoints(){
      if (this.annotation.length!==0){
        for (let i = 0; i< (this.annotation[0].segmentation.length/2)-2; i++){
          if (this.annotation[0].segmentation[i*2+2] === -1) {
            i++;
          }
          else {
            this.eraserArray.push({
              prevX: this.annotation[0].segmentation[i*2] / this.xRatio,
              prevY: this.annotation[0].segmentation[i*2+1]/ this.yRatio,
              x: this.annotation[0].segmentation[i*2+2]/this.xRatio,
              y: this.annotation[0].segmentation[i*2+3]/this.yRatio
            })
            this.canvas.beginPath();
            this.canvas.lineWidth = 5;
            this.canvas.strokeStyle = "yellow";
            this.canvas.lineCap="round";
            this.canvas.moveTo(this.annotation[0].segmentation[i * 2] / this.xRatio, this.annotation[0].segmentation[i * 2 + 1] / this.yRatio);
            this.canvas.lineTo(this.annotation[0].segmentation[i * 2 + 2] / this.xRatio, this.annotation[0].segmentation[i * 2 + 3] / this.yRatio);
            this.canvas.stroke();
          }
        }
      }
    },

    //---------------------------------- BACKEND INTERACTION FUNCTIONS

    async getAmountOfCorrectionImages(){
      await axios.get(VUE_APP_BASE_URL+"/correctionsnr")
      .then(response => (this.imageAmount = response.data.amount))
      .then(error => console.log(error))
    },
    async getImage(){
      const response = await axios.get(VUE_APP_BASE_URL + "/correction")
      this.images = "data:image/png;base64," + response.data[0].base64;
      this.segmentationID.push(response.data[0].annotation.id);
      this.annotation.push(response.data[0].annotation);
      this.imageNameArray.push(response.data[0].annotation.meta_data.filename);
      return this.images;
    },
    async loadImage(isFirst){
      if (isFirst === false){
        this.eraserArray = [];
        for (let i = 0; i< this.keypoints.length; i++) {
          this.keypoints[i].data_points = [];
          this.keypoints[i].point = 0;
          this.keypoints[i].status = "-";
          this.keypoints[i].color = '';
        }
        this.sendingArray = Array.from(Array(34).fill(0));
        this.index= -1;
        this.defaultImage.splice(0,1);
        this.xYRatio.splice(0,2);
        this.annotation.splice(0,1);
        this.segmentationID.splice(0,1);
        this.imageNameArray.splice(0,1);
      }
      var c = this.$refs["canvas"];
      this.canvas = c.getContext("2d");

      var x = document.getElementById("spinner");
      x.style.display = "block";

      var box = document.getElementById("waitloading");
      box.style.display = "block";

      this.getImage().then((bitmap) => {
        this.img = new Image();
        this.img.onload = () => {
          if (this.img.width >= this.img.height) {
            this.imgWidth = 800;
            this.imgHeight = 600;
          } else {
            this.imgWidth = 600;
            this.imgHeight = 800;
          }
          this.$refs.canvas.width = this.imgWidth;
          this.$refs.canvas.height = this.imgHeight;
          this.xRatio = this.img.width / this.imgWidth;
          this.yRatio = this.img.height / this.imgHeight;
          this.xYRatio.push(this.xRatio, this.yRatio)
          this.canvas.drawImage(this.img, 0, 0, this.imgWidth, this.imgHeight);
          this.defaultImage.push(this.canvas.getImageData(0, 0, this.imgWidth, this.imgHeight));
          this.drawSegmentationPoints();
          this.drawExistingKeypoints();
          this.searchForKeyPoints();
        }
        this.img.src =  bitmap;
        x.style.display = "none";
        box.style.display = "none";
      });
    },

    async next(){
      this.$refs.nextButton.disabled=true;

      this.searchForKeyPoints();
      await this.saveKeypointsToDB();

      this.getAmountOfCorrectionImages().then( () => {
        if (this.imageAmount===0){
          alert("No more images to correct. Please have a look at the other two tasks to annotate more images.")
          window.location = "/Home";
        }else{
          this.loadImage(false);
        }
      });
      this.$refs.nextButton.disabled=false;
    },
  },

  mounted() {
    $('body').on('contextmenu', '#myCanvas', function(){ return false; });
    this.getAmountOfCorrectionImages();
    this.loadImage(true);
  },

}
</script>


<style scoped>
.keypoint-box{
  flex-direction: row;
  display: flex;
}
</style>
