/*
* Handles the different routes (url) for the application
* Loads the .vue files accordingly
*/

import  {createRouter, createWebHistory} from 'vue-router';
/*import VueStorageHelper from 'vue-storage-helper'
import storageHelper from 'storage-helper'*/
import About from "@/components/about/About"
import Keypoints from "@/components/keypoints/Keypoints";
import Segmentation from "@/components/segmentation/Segmentation";
import Correction from "@/components/correction/Correction";
import Protected from "@/components/protected/Protected";

/* Configure the router with the different routes */
const routes = [
  {
    path: '/',
    redirect: 'Home',
    /*meta: {
      requiresAuth: true
    }*/
  },
  {
    path: '/',
    name: 'Home',
    /*meta: {
      requiresAuth: true
    }*/
  },
  {
    path:"/correction",
    name:"Correction",
    component: Correction,
    /*meta: {
      requiresAuth: true
    }*/
  },
  {
    path: "/about",
    name: "About",
    component: About
  },
  {
    path: '/segmentation',
    name: 'Segmentation',
    component: Segmentation,
    /*meta: {
      requiresAuth: true
    }*/
  },
  {
    path:"/keypoints",
    name: Keypoints,
    component: Keypoints,
    /*meta: {
      requiresAuth: true
    }*/
  },
  {
    path:"/protected",
    name: Protected,
    component: Protected,
    /*meta: {
      requiresAuth: false
    }*/
  },
  {
    path: '/:pathMatch(.*)*',
    component: () => import('../homepage/Home.vue'), /* webpackChunkName: "about" */
    /*meta: {
      requiresAuth: true
    }*/
  },
]

/* Creates a new router with the parameters implemented above */
let router = createRouter({
    history:createWebHistory(),
    routes,
})

/*router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth){
    if (!storageHelper.getItem('user-password')){
      next('/protected')
    } else {
      next()
    }
  } else {
    next()
  }
});*/

export default router
