<template>
  <div id="app">
    <div style="flex-direction: row; display: flex; width: 90%">
      <h1>Keypoints</h1><a href="#" id="modal_btn"  @click='revealmodal()'><i class="fi-info size-72"></i></a>
    </div>
    <div class="keypoint-box">
      <div id="spinner"><img :src="require('@/assets/images/spinner.gif')" alt="Next image is loading"/></div>
      <div id="waitloading"></div>
      <canvas ref="canvas" id="myCanvas"
            style="border: solid 2px black; cursor: crosshair;"
            width="800"
              height="500"
            @click.left="setKeypoint"
      />

      <div id="info_modal" class="modal_main">
        <div class="modal_content">
          <span class="close" @click='closemodal()'>&times;</span>
          <h3>Keypoints</h3>
          <p>The keypoints task consists of marking each body previously segmented with
          keypoints corresponding to the different joints of the body. You can follow
          the list of keypoints from the right hand side and press enter for each marker applied
          on the image, or apply a marker and check the corresponding mark to register it. For more info, check the video bellow.</p>
          <video width="800" controls>
            <source :src="require('@/assets/videos/papa_keypoints_example.mp4')" type="video/mp4">
          Your browser does not support the video tag.
          </video>

        </div>
      </div>

     <div style="margin-left: 20px">
       <h6>Images to be annotated: {{this.imageAmount}}</h6>
      <h3>Keypoints Toolbox</h3>
      <div style="overflow-y:auto; height:400px; margin-top: 10px;">
        <table class="table table-bordered">
          <thead>
          <tr>
            <th scope="col">Segmentation: {{this.segmentationID[0]}}</th>
            <th scope="col">Status</th>
            <th scope="col" class="text-center">#</th>
            <th scope="col" class="text-center">#</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(k,index) in keypoints" :key="index">
            <td style="display: flex">{{k.name}}
              <div :style="{'background-color': k.color}" style="width: 10px; height: 10px; align-self: center; margin-left: auto; margin-right: 0;">
              </div></td>
            <td>{{k.status}}</td>
            <td>
              <div class="text-center">
                <span class="fa fa-check" @click="saveKeyPoints(index)" style="cursor: pointer"></span>
              </div>
            </td>
            <td>
              <div class="text-center">
                <span class="fa fa-trash" @click="partialDelete(index)" style="cursor: pointer"></span>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <button ref="zoomButton" class="hollow button secondary" style="text-align: center; align-self: center; margin-left: 100px; margin-top: 20px;" @click="zoom">Zoom</button>

      <button ref="nextButton" class="hollow button secondary" style="text-align: center; align-self: center; margin-left: 100px; margin-top: 20px;" @click="next">Next</button>
    </div>
  </div>
  </div>
</template>

<script>
import axios from "axios";
import {VUE_APP_BASE_URL} from "../../../config/dev.env";
import $ from 'jquery';

export default {
  name:"Keypoints",

  data(){
    return{
      fileName: null,
      startX: null,
      startY: null,
      endX: null,
      endY: null,
      imageNameArray: [],
      canvas: null,
      imageCounter:0,
      imageAmount: null,
      imgWidth: "800",
      checkFirst:false,
      imgHeight: "500",
      loadingImage: null,
      sendingArray: Array.from(Array(34).fill(0)),
      emptyPoint: false,
      isSaved: "saved",
      notSaved: "-",
      zeroCounter:0,
      xRatio: null,
      yRatio: null,
      segmentationIDindex: 0,
      segmentationID: [],
      checkIfSaved: false,
      maskedImagedIndex: 0,
      maskedImages: null,
      defaultImage: [],
      defaultImageindex: 0,
      colorIndex:0,
      pointCounter: 0,
      undoArray:[],
      index: -1,
      hexColors:
          ['#F01410',
            '#F07010',
            '#F0C110',
            '#DDE520',
            '#A0E520',
            '#5DFC00',
            '#1FA50D',
            '#70FB94',
            '#1BBE85',
            '#22FBE2',
            '#2D7681',
            '#0D70B5',
            '#0754DF',
            '#062B6C',
            '#9671ED',
            '#9C2DEC',
            '#DE5CF6',
            '#800596',
            '#FF2DD7',
            '#EF2E91'
          ],
      points:[],
      keypoints: [
          {
          point: 0,
          name:"Nose",
          status: "-",
          color:'',
          data_points: []
        },
          {
            point: 0,
            name:"Left Eye",
            status: "-",
            color:'',
            data_points:[],
          },
        {
          point: 0,
          name:"Right Eye",
          status: "-",
          color:'',
          data_points: []
        },
        {
          point: 0,
          name:"Left Ear",
          status: "-",
          color:'',
          data_points: []
        },
        {
          point: 0,
          name:"Right Ear",
          status: "-",
          color:'',
          data_points: []
        },
        {
          point: 0,
          name:"Left Shoulder",
          status: "-",
          color:'',
          data_points: []
        },
        {
          point: 0,
          name:"Right Shoulder",
          status: "-",
          color:'',
          data_points: []
        },
        {
          point: 0,
          name:"Left Elbow",
          status: "-",
          color:'',
          data_points: []
        },
        {
          point: 0,
          name:"Right Elbow",
          status: "-",
          color:'',
          data_points: []
        },
        {
          point: 0,
          name:"Left Wrist",
          status: "-",
          color:'',
          data_points: []
        },
        {
          point: 0,
          name:"Right Wrist",
          status: "-",
          color:'',
          data_points: []
        },
        {
          point: 0,
          name:"Left Hip",
          status: "-",
          color:'',
          data_points: []
        },
        {
          point: 0,
          name:"Right Hip",
          status: "-",
          color:'',
          data_points: []
        },
        {
          point: 0,
          name:"Left Knee",
          status: "-",
          color:'',
          data_points: []
        },
        {
          point: 0,
          name:"Right Knee",
          status: "-",
          color:'',
          data_points: []
        },
        {
          point: 0,
          name:"Left Ankle",
          status: "-",
          color:'',
          data_points: []
        },
        {
          point: 0,
          name:"Right Ankle",
          status: "-",
          color:'',
          data_points: []
        }
      ]

    }
  },
  methods: {

    //-------------------------------- MODAL FUNCTIONS
    revealmodal(){
      // Get the modal
      var modal = document.getElementById("info_modal");
      modal.style.display = "block";

    },
    closemodal(){
      // Get the modal
      var modal = document.getElementById("info_modal");
      modal.style.display = "none";
    },
    //-------------------------------- DRAWING FUNCTIONS
    setKeypoint(e){
      if (this.pointCounter === 0) {
        const rect = this.$refs.canvas.getBoundingClientRect();
        this.startX = e.clientX - rect.left -2.5; // -2.5 is to center the point in the cursor
        this.startY = e.clientY - rect.top -2;
        this.canvas.beginPath();
        this.canvas.arc(this.startX, this.startY, 5, 0, 2 * Math.PI)
        this.canvas.fillStyle = this.hexColors[this.colorIndex];
        this.canvas.fill();
        this.points.push(this.startX, this.startY);
        this.undoArray.push(this.canvas.getImageData(0, 0, this.imgWidth, this.imgHeight));
        this.index += 1;
        this.pointCounter += 1;
        this.emptyPoint = true;
        this.checkIfSaved = false;
      }
      else {
        this.undo();
        this.pointCounter = 0;
        this.points = [];
        this.setKeypoint(e);
        this.drawConnectionLines();
      }
    },

    partialDelete(index){
      if (this.checkIfSaved){
        this.canvas.putImageData(this.defaultImage.at(-1),0,0);
        this.keypoints[index].point=0;
        for (let i = 0; i < this.keypoints.length; i++){
          if (this.keypoints[i].point===1){
          this.canvas.beginPath();
          this.canvas.arc(this.keypoints[i].data_points[0], this.keypoints[i].data_points[1], 5, 0, 2 * Math.PI)
          this.canvas.fillStyle = this.keypoints[i].color;
          this.canvas.fill();
          }
        this.deleteKeyPoints(index);
        }
        this.sendingArray[index*2] = 0;
        this.sendingArray[index*2+1] = 0;
        this.undoArray.push(this.canvas.getImageData(0, 0, this.imgWidth, this.imgHeight));
        this.index += 1;
        this.drawConnectionLines();
      }
    },

    clear_canvas(){
      if (this.undoArray.length!==0){
        this.canvas.putImageData(this.defaultImage.at(-1),0,0)
        this.index= -1;
        this.undoArray=[];
      }
    },

    undo(){
      if (this.index>0){
        this.index-= 1;
        this.undoArray.pop();
        this.canvas.putImageData(this.undoArray[this.index],0,0);
      }
      else {
        this.clear_canvas();
        this.index= -1;
        this.undoArray=[];
      }
    },

    drawConnectionLines(){
      // Left eye - nose
      if(this.keypoints[0].point === 1 && this.keypoints[1].point === 1){
        this.drawLine(this.keypoints[0].data_points, this.keypoints[1].data_points)
      }
      // right eye - nose
      if(this.keypoints[0].point === 1 && this.keypoints[2].point === 1){
        this.drawLine(this.keypoints[0].data_points, this.keypoints[2].data_points)
      }
      // Left ear - left eye
      if(this.keypoints[3].point === 1 && this.keypoints[1].point === 1){
        this.drawLine(this.keypoints[3].data_points, this.keypoints[1].data_points)
      }
      // right ear - right eye
      if(this.keypoints[4].point === 1 && this.keypoints[2].point === 1){
        this.drawLine(this.keypoints[4].data_points, this.keypoints[2].data_points)
      }
      // left shoulder - right shoulder
      if(this.keypoints[5].point === 1 && this.keypoints[6].point === 1){
        this.drawLine(this.keypoints[5].data_points, this.keypoints[6].data_points)
      }
      // left shoulder - left elbow
      if(this.keypoints[5].point === 1 && this.keypoints[7].point === 1){
        this.drawLine(this.keypoints[5].data_points, this.keypoints[7].data_points)
      }
      // right shoulder - right elbow
      if(this.keypoints[6].point === 1 && this.keypoints[8].point === 1){
        this.drawLine(this.keypoints[6].data_points, this.keypoints[8].data_points)
      }
      // left elbow - left wrist
      if(this.keypoints[7].point === 1 && this.keypoints[9].point === 1){
        this.drawLine(this.keypoints[7].data_points, this.keypoints[9].data_points)
      }
      // right elbow - right wrist
      if(this.keypoints[8].point === 1 && this.keypoints[10].point === 1){
        this.drawLine(this.keypoints[8].data_points, this.keypoints[10].data_points)
      }
      // left shoulder - left hip
      if(this.keypoints[5].point === 1 && this.keypoints[11].point === 1){
        this.drawLine(this.keypoints[5].data_points, this.keypoints[11].data_points)
      }
      // right shoulder - right hip
      if(this.keypoints[6].point === 1 && this.keypoints[12].point === 1){
        this.drawLine(this.keypoints[6].data_points, this.keypoints[12].data_points)
      }
      // left hip - right hip
      if(this.keypoints[11].point === 1 && this.keypoints[12].point === 1){
        this.drawLine(this.keypoints[11].data_points, this.keypoints[12].data_points)
      }
      // left hip - left knee
      if(this.keypoints[11].point === 1 && this.keypoints[13].point === 1){
        this.drawLine(this.keypoints[11].data_points, this.keypoints[13].data_points)
      }
      // right hip - right knee
      if(this.keypoints[12].point === 1 && this.keypoints[14].point === 1){
        this.drawLine(this.keypoints[12].data_points, this.keypoints[14].data_points)
      }
      // left knee - left ankle
      if(this.keypoints[13].point === 1 && this.keypoints[15].point === 1){
        this.drawLine(this.keypoints[13].data_points, this.keypoints[15].data_points)
      }
      // right knee - right ankle
      if(this.keypoints[14].point === 1 && this.keypoints[16].point === 1){
        this.drawLine(this.keypoints[14].data_points, this.keypoints[16].data_points)
      }
    },

    drawLine(start,end){
      this.canvas.beginPath();
      this.canvas.lineWidth = 2;
      this.canvas.strokeStyle = "red";
      this.canvas.moveTo(start[0],start[1])
      this.canvas.lineTo(end[0],end[1]);
      this.canvas.stroke()
    },

    saveKeyPoints(index){
      if (this.emptyPoint === true && this.keypoints[index].status === "-") {
        this.keypoints[index].point = 1;
        this.keypoints[index].data_points = this.points;
        this.sendingArray[index*2] = this.points[0]*this.xRatio;
        this.sendingArray[index*2+1] = this.points[1]*this.yRatio;
        this.keypoints[index].status = this.isSaved;
        this.keypoints[index].color = this.hexColors[this.colorIndex];
        this.points = [];
        if (this.colorIndex <= this.hexColors.length) {
          this.colorIndex += 1;
        } else this.colorIndex = 0;
        this.pointCounter = 0;
        this.emptyPoint = false;
        this.drawConnectionLines();
        this.checkIfSaved = true;
      }
    },

    deleteKeyPoints(index){
      this.keypoints[index].point = 0;
      this.keypoints[index].data_points = [];
      this.keypoints[index].status=this.notSaved;
      this.keypoints[index].color = '';
    },
    //---------------------------------- FUNCTIONAL FUNCTIONS
    //Automatic saving of the next keypoint in list when pressed ENTER
    nextKeypoint(){
      for(let index=0; index<this.keypoints.length; index++){
        if (this.keypoints[index].point === 0){
          this.saveKeyPoints(index);
          break;
        }
      }
    },

    //---------------------------------- BACKEND INTERACTION FUNCTIONS

    async saveKeypointsToDB(){
      let check = false;
      for (let i = 0; i<this.sendingArray.length; i++){
        if (this.sendingArray[i] !== 0){
          check = true;
          break;
        }
      }
      if (check) {
        await axios.put(VUE_APP_BASE_URL+"/keypoint/"+this.segmentationID[0],
          {
            keypoints: this.sendingArray,
            meta_data: {
              "filename": this.imageNameArray[0],
              "keypoints": this.isSaved,
            }
          })
      }
      else {
        await axios.put(VUE_APP_BASE_URL+"/keypoint/"+this.segmentationID[0],
          {
            keypoints: [],
            meta_data: {
              "filename": this.imageNameArray[0]
            }
          })
      }
    },

    async getAmountOfKeypointImages(){
      await axios.get(VUE_APP_BASE_URL+"/keypointnr")
      .then(response => (this.imageAmount = response.data.amount))
      .then(error => console.log(error))
    },

    leaveAnnotation(){
      for (let i = 0; i < this.segmentationID.length; i++){
        axios.put(VUE_APP_BASE_URL+"/keypoint/"+this.segmentationID[i],
          {
            keypoints: [],
            meta_data: {
              "filename": this.imageNameArray[i]
            }
          })
      }
    },

    //V.Bernasconi 07.2022
    async getImage(){
      const response = await axios.get(VUE_APP_BASE_URL + "/keypoint")
      this.maskedImages = "data:image/png;base64," + response.data[0].base64;
      this.segmentationID.push(response.data[0].id);
      this.imageNameArray.push(response.data[0].filename);
      return this.maskedImages;
    },

    async loadImage(isFirst){
      if (isFirst === false){
        for (let i = 0; i< this.keypoints.length; i++) {
          this.keypoints[i].data_points = [];
          this.keypoints[i].point = 0;
          this.keypoints[i].status = "-";
          this.keypoints[i].color = '';
        }
        var c = this.$refs["canvas"];
        this.canvas = c.getContext("2d");
      }

      var x = document.getElementById("spinner");
      x.style.display = "block";
      var box = document.getElementById("waitloading");
      box.style.display = "block";
      this.canvas.globalAlpha = 0.2;

      this.getImage().then((bitmap) => {
        this.img = new Image();
          this.img.onload = () => {
            if (this.img.width >= this.img.height){
              this.imgWidth = 800;
              this.imgHeight = 600;
            }
            else {
              this.imgWidth = 600;
              this.imgHeight = 800;
            }
            this.$refs.canvas.width = this.imgWidth;
            this.$refs.canvas.height = this.imgHeight;
            this.xRatio = this.img.width / this.imgWidth;
            this.yRatio = this.img.height / this.imgHeight;
            this.canvas.drawImage(this.img,0,0,this.imgWidth,this.imgHeight);
            this.defaultImage.push(this.canvas.getImageData(0, 0, this.imgWidth, this.imgHeight));
          }
          this.img.src = bitmap;
          x.style.display = "none";
          box.style.display = "none";
          this.canvas.globalAlpha = 1;
          this.canvas.oncontextmenu = function(e) {e.preventDefault();};
      });

      await this.getAmountOfKeypointImages();
    },

    async next(){
      this.$refs.nextButton.disabled=true;
      await this.saveKeypointsToDB();
      await this.getAmountOfKeypointImages();

      if (this.imageAmount === 0){
        alert("All images have been annotated with keypoints.Please have a look at the other two tasks.")
        window.location = "/Home";
      }else{
        this.segmentationIDindex+=1;
        this.sendingArray = Array.from(Array(34).fill(0));
        this.index= -1;
        this.colorIndex = 0;
        this.defaultImage.splice(0,1);
        this.segmentationID.splice(0,1);
        this.imageNameArray.splice(0,1);

        await this.loadImage(false);
      }
      this.$refs.nextButton.disabled=false;
    },
  },

  mounted() {

    $('body').on('contextmenu', '#myCanvas', function(){ return false; });

    var c = this.$refs["canvas"];
    this.canvas = c.getContext("2d");
    this.getAmountOfKeypointImages();
    this.loadImage(true);

    window.addEventListener('keyup', (event) => {
      if (event.keyCode === 13) {
        this.nextKeypoint();
      }
    });


    /*this.canvas.onmousewheel = function (event) => {
      var mousex = event.clientX - this.canvas.offsetLeft;
      var mousey = event.clientY - this.canvas.offsetTop;
      var wheel = event.wheelDelta/120;//n or -n

      var zoom = Math.pow(1 + Math.abs(wheel)/2 , wheel > 0 ? 1 : -1);

      context.translate(
          originx,
          originy
      );
      context.scale(zoom,zoom);
      context.translate(
          -(mousex/scale + originx-mousex/(scale*zoom)),
          -(mousey/scale + originy-mousey/(scale*zoom))
      );

      originx = (mousex/scale + originx-mousex/(scale*zoom));
      originy = (mousey/scale + originy-mousey/(scale*zoom));
      scale *= zoom;
    },*/

  },

  beforeUnmount () {
    window.removeEventListener('keyup', (event) => {
      if (event.keyCode === 13) {
        this.nextKeypoint();
      }
    });
  },
}

</script>


<style scoped>
.keypoint-box{
  flex-direction: row;
  display: flex;
}

</style>
