<template>
<div id="app">
  <div style="flex-direction: row; display: flex; width: 85%">
    <h1>Segmentation</h1><a href="#" id="modal_btn"  @click='revealmodal()'><i class="fi-info size-72"></i></a>
  </div>
  <!--Have a look at this line something is unclear with the background and the corresponding image only a.png works but not clear why
  background="src/components/canvas/a.png"-->
  <div class="canvas-box">
    <div class="canvas-space">
      <div id="spinner"><img :src="require('@/assets/images/spinner.gif')" alt="Next image is loading"/></div>
      <div id="waitloading"></div>
      <canvas class="canvas"
              ref="canvas"
              id="myCanvas"
              width="800" height="500"
              style="border: 2px solid black"
              @mousemove="draw"
              @mousedown.left="beginDrawing"
              @mouseup="stopDrawing"
              />
      </div>
      <div id="info_modal" class="modal_main">
        <div class="modal_content">
          <span class="close" @click='closemodal()'>&times;</span>
          <h3>Segmenting images</h3>
          <p>The segmentation task consists of drawing the contour of each character present on the image. After each contour, save
          the trace by clicking on the check button. For more info, check the video bellow.</p>
          <video width="800" controls>
            <source :src="require('@/assets/videos/papa_segmentation_example.mp4')" type="video/mp4">
          Your browser does not support the video tag.
          </video>
        </div>
      </div>
    <div style="height: 600px;">
      <h6>Images to be annotated: {{this.segmentationAmount}}</h6>
      <h3>Segmentation toolbox</h3>
      <div style="overflow-y:auto; height:300px; margin-top: 10px;">
        <table class="table table-bordered">
          <thead>
          <tr>
            <th scope="col">Segmentation</th>
            <th scope="col">Status</th>
            <th scope="col" class="text-center">#</th>
            <th scope="col" class="text-center">#</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(seg,index) in segmentation" :key="index">
            <td @click="show_segmentation(index)">{{seg.name}}</td>
            <td>{{seg.status}}</td>
            <td>
              <div class="text-center">
                <span class="fa fa-check" style="cursor: pointer;" @click="save_segmentation(index)"></span>
              </div>
            </td>
            <td>
              <div class="text-center">
                <span class="fa fa-trash" style="cursor: pointer;" @click="delete_segmentation(index)"></span>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div>
        <button @click="undo" type="button" style="text-align: center; align-self: center; margin-left: 50px; margin-top: 10px" class="hollow button secondary">Undo</button>
        <button @click="clear_canvas" class="hollow button secondary" style="text-align: center; align-self: center; margin-left: 35px; margin-top: 10px" type="button">Clear</button>
      </div>
      <div class="tools">
    <div ref="blackbtn" @click="change_color_black" class="color-field" style="background: black"></div>
    <div ref="redbtn" @click="change_color_red" class="color-field" style="background: red"></div>
    <div ref="greenbtn" @click="change_color_green" class="color-field" style="background: green"></div>
    <div ref="yellowbtn" @click="change_color_yellow" class="color-field" style="background: yellow"></div>
  </div>
      <button ref="nextButton" class="hollow button secondary" style="text-align: center; align-self: center; margin-left: 100px; margin-top: 10px" @click="next">Next</button>
    </div>
  </div>
</div>
</template>

<script>
import axios from "axios";
import {VUE_APP_BASE_URL} from "../../../config/dev.env";
import $ from 'jquery';

export default {
  name: "Segmentation",
  cache: false,
  components:{
  },
  data(){
    return{
      object_counter:2,
      isSaved:"saved",
      notSaved:"not saved",
      canvas: null,
      zeroCounter:0,
      area: 0,
      checkFirst: false,
      error: null,
      tool: "Eraser",
      eraserWidth: 8,
      dataPoints: [],
      actualFileName: null,
      nextFileName: null,
      loadingImage: null,
      preloadedImage: null,
      idArray: [],
      old: null,
      x: 0,
      y: 0,
      isbbox: false,
      xRatio: null,
      yRatio: null,
      imgWidth: "800",
      imgHeight: "500",
      isDrawing: false,
      segmentation_drawing:[],
      points: [],
      canv: null,
      change: false,
      isPress: false,
      img: null,
      segmentationAmount: 0,
      drawColor: "rgba(255,0,0,1)", //red
      draw_width: 5,
      undoArray:[],
      eraserArray: [],
      index: -1,
      imageIndex: 0,
      segmentationDataPoints: [],
      imageName: "",
      segmentation: [
          {
            name:"Person "+ 1,
            status: "not saved",
            data_points:[],
          }
      ],
      bbox:[
          {
              mostLeftX: null,
              mostRightX: null,
              mostUpY: null,
              mostDownY: null
            }
      ]
    }
  },
  methods: {

      //-------------------------------- MODAL FUNCTIONS
      revealmodal(){
        // Get the modal
        var modal = document.getElementById("info_modal");
        modal.style.display = "block";

      },
      closemodal(){
        // Get the modal
        var modal = document.getElementById("info_modal");
        modal.style.display = "none";
      },

      //-------------------------------- DRAWING FUNCTIONS
      draw(e) {
        if(this.isDrawing) {
          //This is the eraser part
            this.drawLine(this.x, this.y, e.offsetX, e.offsetY);
            this.eraserArray.push({
              prevX: this.x,
              prevY: this.y,
              x: e.offsetX,
              y: e.offsetY
            })
            this.points.push({
              x: e.offsetX * this.xRatio,
              y: e.offsetY * this.yRatio
            })
            this.dataPoints.push(this.x * this.xRatio , this.y * this.yRatio)
            }
          this.x = e.offsetX;
          this.y = e.offsetY;
      },

      drawSegmentationPoints(){
        let ctx = this.canvas;
        for (let i = 0; i< this.eraserArray.length; i++) {
          ctx.beginPath();
          ctx.strokeStyle = this.drawColor;
          ctx.lineWidth = this.draw_width;
          ctx.lineCap="round";
          ctx.moveTo(this.eraserArray[i].prevX, this.eraserArray[i].prevY);
          ctx.lineTo(this.eraserArray[i].x, this.eraserArray[i].y);
          ctx.stroke();
          ctx.closePath();
        }
      },

      //  VBernasconi 07.2022
      setSegmentationPointsOpacity(clear, index){
        var opacity_stroke = 1;
        var opacity_fill = 1;
        if(clear === true){
          opacity_stroke = 0.1;
          opacity_fill = 0.2;
        }
        if (this.segmentationDataPoints[index]!==0 || this.segmentationDataPoints[index]!==undefined){
          for (let i = 0; i< (this.segmentationDataPoints[index].length/2)-2; i++){
            if (this.segmentationDataPoints[index][i*2+2] === -1) {
              i++;
            }else {
              var rgb = this.drawColor.match(/\d+/g);
              var rgba = 'rgba('+rgb[0]+','+rgb[1]+','+rgb[2]+',';

              this.canvas.beginPath();
              this.canvas.lineWidth = 5;
              this.canvas.strokeStyle = rgba + opacity_stroke+')';
              this.canvas.fillStyle = rgba + opacity_fill+')';
              this.canvas.lineCap="round";
              this.canvas.moveTo(this.segmentationDataPoints[index][i * 2] / this.xRatio, this.segmentationDataPoints[index][i * 2 + 1] / this.yRatio);
              this.canvas.lineTo(this.segmentationDataPoints[index][i * 2 + 2] / this.xRatio, this.segmentationDataPoints[index][i * 2 + 3] / this.yRatio);
              this.canvas.stroke();
            }
          }
        }
      },

      show_segmentation(index){
        //clear canvas
        //set opacity again for each element by index
        this.clear_canvas();

        for (let i=0; i<this.segmentation_drawing.length; i++){
          if(i===index){
            this.setSegmentationPointsOpacity(false, index);
          }else{
            this.setSegmentationPointsOpacity(true, i);
          }
        }
      },

      createPost(){
        for (let i = 0; i< this.segmentationDataPoints.length; i++){
          console.log(this.imageName);
          console.log(this.idArray[0]);
          axios.post(VUE_APP_BASE_URL+"/annotations/1/"+String(this.idArray[0]), {
          segmentation: this.segmentationDataPoints[i],
          bbox: this.bbox[i],
          area: this.area,
          iscrowd: false,
          isbbox: true,
          keypoints: [],
          meta_data: {"filename": this.imageName},
          status: 1})
        .then(response => console.log(response))
        .then(error => console.log(error))
        }
      },

      save_segmentation(index){
        for (let i = 0; i<=this.points.length; i++){
          if (this.points[i]===-1){
            this.points.splice(i,1);
            i--;
          }
        }
        if (this.segmentation[index].status !== "saved"){

          this.segmentation[index].data_points=this.points;
          this.segmentation[index].status=this.isSaved
          this.segmentation_drawing.push(this.canvas.getImageData(0,0,this.imgWidth,this.imgHeight));

          this.points = []
          this.segmentationDataPoints.push(this.dataPoints);

          this.dataPoints = [];
          this.searchForKeyPoints(index);
          this.eraserArray = [];

          let ctx = this.canvas;
          ctx.closePath();
          ctx.fillStyle = this.drawColor;
          ctx.fill();

          //VBernasconi 07.2022
          this.show_segmentation(true, null);
        }
        this.add();
      },

      searchForKeyPoints(index){
        var x = this.segmentation[index].data_points.map(function(p) {return p.x});
        var y = this.segmentation[index].data_points.map(function(p) {return p.y});
        this.bbox[index].mostLeftX = Math.min.apply(null, x);
        this.bbox[index].mostUpY = Math.min.apply(null, y);
        this.bbox[index].mostRightX = Math.max.apply(null, x);
        this.bbox[index].mostDownY = Math.max.apply(null, y);
      },

      add(){
        console.log(this.segmentation.length)
        if (this.segmentation.length === 0){
          console.log(this.segmentation.length)
          this.object_counter = 1;
        }
        this.segmentation.push({name:"Person "+ this.object_counter,
          status:"not saved",
          data_points:[],
        });
        this.bbox.push({
              mostLeftX: null,
              mostRightX: null,
              mostUpY: null,
              mostDownY: null
            })
        this.object_counter += 1;
      },

      remove(){
        this.segmentation.pop();
        this.segmentation_drawing.pop();
        this.segmentationDataPoints.pop();
        if(this.segmentation.length===0){
          this.object_counter = 1;
        }
        else{this.object_counter -= 1;}
      },

      delete_segmentation(index){
        this.segmentation.splice(index,1);
        this.segmentation_drawing.splice(index,1)
        this.segmentationDataPoints.splice(index,1)
        this.bbox.splice(index,1)
        this.show_segmentation(null);
      },

      drawLine(x1, y1, x2, y2) {
        let ctx = this.canvas;
        ctx.beginPath();
        ctx.strokeStyle = this.drawColor;
        ctx.lineWidth = this.draw_width;
        ctx.lineCap="round";
        ctx.moveTo(x1, y1);
        ctx.lineTo(x2, y2);
        ctx.stroke();
        ctx.closePath();
      },

      beginDrawing(e) {
        this.isDrawing = true;
        if(!this.isPress){
          this.x = e.offsetX;
          this.y = e.offsetY;
          this.points.push({
            x: e.offsetX * this.xRatio,
            y: e.offsetY * this.yRatio
          })
          this.dataPoints.push(this.x * this.xRatio,this.y * this.yRatio)
        }
      },

      stopDrawing(e) {
        if (this.isDrawing && !this.isPress) {
          this.drawLine(this.x, this.y, e.offsetX, e.offsetY);
          this.x = 0;
          this.y = 0;
          this.isDrawing = false;
          this.undoArray.push(this.canvas.getImageData(0,0,this.imgWidth,this.imgHeight));
          this.index+=1;
          // shows the segmentation where it stopped
          this.points.push(-1,-1)
          this.dataPoints.push(-1, -1)
        }
        else this.isDrawing = false;
      },

      change_color_red(){
        this.drawColor="rgba(255,0,0,1)"; //red
        this.$refs.blackbtn.style.border = ""
        this.$refs.yellowbtn.style.border = ""
        this.$refs.redbtn.style.border = "3px solid #888888"
        this.$refs.greenbtn.style.border = ""
      },

      change_color_yellow(){
        this.drawColor="rgba(255,240,0,1)"; //yellow
        this.$refs.blackbtn.style.border = ""
        this.$refs.yellowbtn.style.border = "3px solid #888888"
        this.$refs.redbtn.style.border = ""
        this.$refs.greenbtn.style.border = ""
      },

      change_color_green(){
        this.drawColor="rgba(0,255,0,1)"; //green
        this.$refs.blackbtn.style.border = ""
        this.$refs.yellowbtn.style.border = ""
        this.$refs.redbtn.style.border = ""
        this.$refs.greenbtn.style.border = "3px solid #888888"
      },

      change_color_black(){
        this.drawColor="rgba(0,0,0,1)"; //black
        this.$refs.blackbtn.style.border = "3px solid #888888"
        this.$refs.yellowbtn.style.border = ""
        this.$refs.redbtn.style.border = ""
        this.$refs.greenbtn.style.border = ""
      },

      clear_canvas(){
        this.canvas.drawImage(this.img,0,0,this.imgWidth,this.imgHeight)
        this.index= -1;
        this.undoArray=[];
      },

      undo(){
        let counter = 0;
        for(let i = this.points.length-1; i >=0; i--){
          if (this.points[i]===-1){
            counter+=1;
          }
          if (counter === 3){
            break;
          }
          this.points.splice(i,1);
          this.dataPoints.splice(2*i-2,2);
        }
        if (this.index>0){
          this.index-= 1;
          this.undoArray.pop();
          this.canvas.putImageData(this.undoArray[this.index],0,0);
        } else {
          this.clear_canvas();
          this.index= -1;
          this.undoArray=[];
        }
      },

      download() {
        // can be added to download image
        // <button @click="download" class="hollow button secondary" type="button">Download</button>
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.href = this.$refs["canvas"].toDataURL();
        // set the download name
        a.download = "canvas-image.png";
        a.click();
        document.body.removeChild(a);
      },

      //---------------------------------- BACKEND INTERACTION FUNCTIONS

      sendEmptyPost(){
          console.log(this.imageName);
          console.log(this.idArray[0]);
          axios.post(VUE_APP_BASE_URL+"/annotations/1/"+String(this.idArray[0]), {
          segmentation: [],
          bbox: [0, 0, 0, 0],
          area: 0,
          iscrowd: false,
          isbbox: false,
          keypoints: [],
          meta_data: {"filename": this.imageName},
          status: 2})
        .then(response => console.log(response))
        .then(error => console.log(error))
      },

      async getSegmentationAmount(){
        await axios.get(VUE_APP_BASE_URL+"/segmentationnr")
        .then(response => (this.segmentationAmount = response.data.amount))
        .then(error => console.log(error))
      },

      async getImage() {
        console.log("A new image was requested");
        const response = await axios.get(VUE_APP_BASE_URL+"/segmentation")
        this.loadingImage = "data:image/png;base64," + response.data[0].base64;
        this.fileName = response.data[0].filename;
        this.idArray.push(response.data[0].id);
        this.imageName = this.fileName;
        console.log(this.imageName);
        return this.loadingImage;
      },

      async loadImage(isFirst){
        if (isFirst === false){
          this.index= -1;
          this.idArray.splice(0,1);
          this.object_counter = 1;
          this.segmentation_drawing = [];
          this.segmentation = [{
                name:"Person "+ 1,
                status: "not saved",
                data_points:[],
              }];
          this.bbox = [{
                  mostLeftX: null,
                  mostRightX: null,
                  mostUpY: null,
                  mostDownY: null
                }];
          this.dataPoints = [];
          this.segmentationDataPoints = [];
        }

        var c = this.$refs["canvas"];
        this.canvas = c.getContext("2d");

        var x = document.getElementById("spinner");
        var box = document.getElementById("waitloading");

        x.style.display = "block";
        box.style.display = "block";

        this.canvas.globalAlpha = 0.2;
        this.getImage().then((bitmap) => {
            console.log("Getting new image");
            this.img = new Image();
            this.img.onload = () => {
              if (this.img.width >= this.img.height){
                this.imgWidth = 800;
                this.imgHeight = 600;
              }else {
                this.imgWidth = 600;
                this.imgHeight = 800;
              }
              this.$refs.canvas.width = this.imgWidth;
              this.$refs.canvas.height = this.imgHeight;
              this.xRatio = this.img.width / this.imgWidth;
              this.yRatio = this.img.height / this.imgHeight;
              this.canvas.drawImage(this.img,0,0,this.imgWidth,this.imgHeight);
            }
            x.style.display = "none";
            box.style.display = "none";
            this.canvas.globalAlpha = 1;
            this.img.src = bitmap;

        });
        await this.getSegmentationAmount();
      },

      async next(){
        this.$refs.nextButton.disabled=true;
        if (this.segmentationDataPoints.length!==0){
          await this.createPost();
        }else{
          let confirmAction = confirm("Are you sure there is nothing to annotate on this image?");
          if (confirmAction) {
            await this.sendEmptyPost();
          } else {return;}
        }

        await this.getSegmentationAmount();

        if (this.segmentationAmount === 0){
          alert("All images have been segmented. Please have a look at the other tasks.");
          window.location = "/Home";
        }else{
          await this.loadImage(false);
        }
        this.$refs.nextButton.disabled=false;
      },
    },
    mounted() {
      $('body').on('contextmenu', '#myCanvas', function(){ return false; });
      this.getSegmentationAmount();
      this.loadImage(true);
      this.$refs.blackbtn.style.border = "3px solid #888888";
    },
  }
</script>

<style scoped>
.canvas{
  cursor: crosshair;
  margin-right: 15px;
}

.canvas-box{
  display: flex;
  flex-direction: row;
}

.tools{
  display: flex;
  justify-content: left;
  flex-direction: row;
  margin-top: 10px;
  margin-left: 33px;
}

.tools .color-field {
    height: 40px;
    width: 40px;
    min-height: 40px;
    min-width: 40px;
    cursor: pointer;
    display: inline-block;
    box-sizing: border-box;
    border-radius: 30%;
    align-self: center;
    margin: 0 5px;
    margin-bottom: 30px;
}

.modal {
  position: fixed;
  width: 300px;
  padding: 30px;
  box-sizing: border-box;
  background-color: #fff;
  font-size: 20px;
  text-align: center;
  transform: translate(-50%, -50%);
  z-index: 99;
}
</style>
