/**
 * An html page to present the project and the different members working on it
 *
 * @todo to be implemented and link to the route system and home page
 */
<template>
   <div>
     <h1>This is an about page</h1>
   </div>
</template>

<script>
export default {
  name: 'About',
  components: {
  },
  data(){
    return{
    }

  },
  methods:{

  },
  mounted() {
  }
}
</script>

<style scoped>


</style>