import { createApp } from 'vue';
import App from './App.vue';
import "bootstrap/dist/css/bootstrap.min.css";
import { createAuth0 } from '@auth0/auth0-vue';
import router from "@/components/routers/routers";


const app = createApp(App)
app.use(
  createAuth0({
    domain: "dev-lit788kn.us.auth0.com",
    client_id: "t5Y7VpWNSk36oqwQnQtMlmRfsaeUqN1R",
    redirect_uri: window.location.origin
  })
)
app.use(router)
app.mount('#app')
