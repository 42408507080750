/**
 * Initiate the application
 * Import required css files and router
 */

<template>
  <div id="app">
    <router-view/>
  </div>
</template>


<style>
  @import './assets/css/foundation.css';
  @import './assets/css/foundation-icons/foundation-icons.css';
  @import "~@fortawesome/fontawesome-free/css/all.css";
</style>
